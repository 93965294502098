import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NAVBAR_PATHS } from '../../utils/constants/paths';
import { CONTENT } from '../../utils/mockData/homeContent';

export const GenericNavbar = () => {
  return (
    <header>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand>
            <img src="/sara_franca_logo.png" alt="Logo Sara França, um S em rosa escuro e um F em verde claro." width="60" height="60" />
          </Navbar.Brand>
          <Navbar.Text className="header-title flex-grow-1 d-none d-md-flex d-lg-flex fs-5">
            Sara França Nutricionista
          </Navbar.Text>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="mx-2">
            <Nav className="me-auto">
              <Nav.Link href={`#${NAVBAR_PATHS.services}`} className="nav-link text-center d-flex justify-content-center align-items-center">
                {CONTENT.navbar.services}
              </Nav.Link>
              <Nav.Link href={`#${NAVBAR_PATHS.schedule}`} className="nav-link text-center d-flex justify-content-center align-items-center">
                {CONTENT.navbar.schedule}
              </Nav.Link>
              <Nav.Link href={`#${NAVBAR_PATHS.slider}`} className="nav-link text-center d-flex justify-content-center align-items-center">
                {CONTENT.navbar.slider}
              </Nav.Link>
              <Nav.Link href={`#${NAVBAR_PATHS.stay_connected}`} className="nav-link text-center d-flex justify-content-center align-items-center">
                {CONTENT.navbar.stay_connected}
              </Nav.Link>
              <Nav.Link href={`#${NAVBAR_PATHS.book}`} className="nav-link text-center d-flex justify-content-center align-items-center">
                {CONTENT.navbar.book}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};
