import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export const Location = () => {
  return (
    <section className="w-100 d-flex flex-column justify-content-center align-items-center px-4" style={{ backgroundColor: '#BDDB99', transform: 'translateY(-2px)' }}>
      <div className="d-flex p-4 align-items-center justify-content-center mx-4">
        <h3 className="display-5 fs-6 text-primary position-relative text-center" style={{ maxWidth: '900px' }}>
          <strong>
            O período da gestação e os dois primeiros ano de vida são decisivos para o crescimento e desenvolvimento da criança.
          </strong>
          <span className="position-absolute fs-6" style={{ color: '#3A5519', right: '-25px', bottom: '-25px' }}>
            <strong>
              Ministério da Saúde
            </strong>
          </span>
        </h3>
      </div>
      <div className="d-flex flex-wrap-reverse mx-4 justify-content-center align-items-center py-5 gap-5 w-100" style={{ maxWidth: '900px' }}>
        <div className="flex-grow-1 d-flex justify-content-center align-items-center flex-column">
          <p className="display-5 fs-5 text-center text-primary m-0"> <strong> Dúvidas sobre o atendimento nutricional? </strong> </p>
          <p className="display-5 fs-5 text-center mt-2" style={{ color: '#3A5519' }}> <strong> Entre em contato comigo </strong> </p>
          <ul className="list-unstyled d-flex align-items-center justify-content-center flex-wrap gap-5 fs-3">
            <li>
              <a href="mailto:nutrirnf@gmail.com" target="_blank" rel="noreferrer" aria-label="Enviar e-mail para Sara França">
                <FontAwesomeIcon icon={['fas', 'envelope']} role="img" />
              </a>
            </li>
            <li>
              <a href="tel:5521967865700" target="_blank" rel="noreferrer" aria-label="Ligar para Sara França">
                <FontAwesomeIcon icon={['fas', 'phone']} role="img" />
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/profile.php?id=100000989012457&mibextid=LQQJ4d" target="blank" rel="noreferrer" aria-label="Direcionamento ao perfil do Facebook de Sara França">
                <FontAwesomeIcon icon={['fab', 'facebook-f']} role="img" />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/sarafrancanutrir/" target="_blank" rel="noreferrer" aria-label="Direcionamento ao perfil do Instagram de Sara França">
                <FontAwesomeIcon icon={['fab', 'instagram']} role="img" />
              </a>
            </li>
            <li>
              <a href="https://tiktok.com/@sarafrancanutrir" target="_blank" rel="noreferrer" aria-label="Direcionamento ao perfil do TikTok de Sara França">
                <FontAwesomeIcon icon={['fab', 'tiktok']} role="img" />
              </a>
            </li>
          </ul>
        </div>
        <div className="shadow d-flex align-items-center justify-content-center bg-white rounded-circle p-3" style={{ height: '350px', width: '350px', minHeight: '300px', minWidth: '300px' }}>
          <img alt="Sara França" src="/sara_mao_queixo.png" style={{ maxHeight: '100%', maxWidth: '100%', transform: 'translateX(3px)' }} />
        </div>
      </div>
      <div className="d-flex pb-5 w-100 justify-content-center align-items-center px-5" style={{ maxWidth: '900px' }}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3674.3849171224692!2d-43.33619022390169!3d-22.936047138918973!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9bd82697b98b8d%3A0x6f0e48437473b765!2sEstr.%20dos%20Tr%C3%AAs%20Rios%2C%201097%20-%20Freguesia%20(Jacarepagu%C3%A1)%2C%20Rio%20de%20Janeiro%20-%20RJ%2C%2022745-004!5e0!3m2!1spt-BR!2sbr!4v1707177985287!5m2!1spt-BR!2sbr"
          className="border-0 shadow rounded-2 w-100"
          style={{ height: '400px' }}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Endereço do consultório de Sara França"
        >
        </iframe>
      </div>
      <div style={{ maxWidth: '900px' }} className="w-100">
        <p className="text-center display-5 fs-5" style={{ color: '#3A5519' }}> <strong> Estrada dos Três Rios, nº 1097, sala 716 - Freguesia / Jacarepaguá </strong> </p>
      </div>
      <div style={{ transform: 'translateY(-5px) rotate(180deg)' }}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path fill="#BDDB99" fillOpacity="1" d="M0,0L48,32C96,64,192,128,288,170.7C384,213,480,235,576,245.3C672,256,768,256,864,240C960,224,1056,192,1152,186.7C1248,181,1344,203,1392,213.3L1440,224L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
        </svg>
      </div>
    </section>
  )
}