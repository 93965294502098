import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DIET_BOX_LINK, WPP_PERSONAL } from "../../../../utils/mockData/homeContent"
import { NAVBAR_PATHS } from '../../../../utils/constants/paths'

export const Schedule = () => {
  return (
    <section className="w-100">
      <div style={{ backgroundColor: '#F8F9FA' }}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#b81250" fillOpacity="1" d="M0,224L48,234.7C96,245,192,267,288,266.7C384,267,480,245,576,224C672,203,768,181,864,186.7C960,192,1056,224,1152,234.7C1248,245,1344,235,1392,229.3L1440,224L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
      </div>
      <section id={NAVBAR_PATHS.schedule} style={{ transform: 'translateY(-2px)' }} className="w-100 h-100 bg-primary d-flex align-items-center justify-content-center pt-3 text-white flex-wrap">
        <div className="kids-container align-items-center justify-content-center d-none">
          <img src="/criancas.png" alt="Crianças" style={{ width: '300px', transform: 'translateX(-93px)' }} />
        </div>
        <div style={{ maxWidth: '600px', minWidth: '300px' }} role="contentinfo" className="d-flex flex-column gap-3 px-5 flex-grow-1">
          <h2 className="display-5 fw-light text-center sara-title" aria-label="Título 'Quem é sua nutricionista?'"> <strong> Quem é a sua nutricionista </strong> </h2>
          <p style={{ textAlign: 'justify' }} aria-label="Formação e trabalho atual da nutricionista Sara França">
            Sou Nutricionista formada pela Universidade Gama Filho e Pós graduada  na Universidade Federal Fluminense em Alimentação infantil e Qualidade Alimentar.
            Minhas condutas são baseadas em atuações em  hospitais, escolas, home care e em meu consultório, no qual atuo <strong>há mais de 10 anos. </strong>
            Sou uma estudiosa e entusiasta da disciplina positiva, criação com amor e desenvolvimento da inteligência emocional.
            Sei, na prática como, é desafiador fazer com que as crianças se alimentem bem! Por isso uso em meus atendimentos ferramentas e técnicas práticas e lúdicas, ajudando ainda mais as mães a conduzirem a alimentação dos seus filhos de maneira respeitosa e tranquila.
            Sei também que nós mulheres precisamos de atenção especial quando falamos de planejar nosso corpo para maternidade. Por isso, meu foco é atender mulheres e crianças, e disseminar <strong>nutrição de qualidade</strong> em todas as fases da vida!
          </p>
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center mx-3">
          <img style={{ width: '250px' }} src="sara_apontando.png" alt="Sara França apontando para os botões de agendamento" />
          <div className="d-flex mt-2 flex-wrap gap-3 mb-5 justify-content-center flex-wrap">
            <a
              className="shadow btn bg-white d-flex gap-3 align-items-center text-center justify-content-center"
              style={{ color: '#222!important', minWidth: '220px' }}
              href={DIET_BOX_LINK}
              target="_blank"
              rel="noreferrer"
              aria-label="Abrir link para realizar um agendamento com Sara França"
            >
              <FontAwesomeIcon icon={['fas', 'calendar-alt']} role="img" />
              Faça um agendamento
            </a>
            <a
              className="shadow btn bg-white d-flex gap-3 align-items-center text-center justify-content-center"
              style={{ color: '#222!important', minWidth: '220px' }}
              href={WPP_PERSONAL}
              target="_blank"
              rel="noreferrer"
              aria-label="Abrir link para entra em contato com Sara França pelo WhatsApp"
            >
              <FontAwesomeIcon icon={['fab', 'whatsapp']} role="img" />
              Entre em contato
            </a>
          </div>
        </div>
      </section>
      <div style={{ transform: 'translateY(-5px) rotate(180deg)' }}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#b81250" fillOpacity="1" d="M0,224L48,234.7C96,245,192,267,288,266.7C384,267,480,245,576,224C672,203,768,181,864,186.7C960,192,1056,224,1152,234.7C1248,245,1344,235,1392,229.3L1440,224L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
      </div>
    </section>
  )
}