import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMemo, useState } from 'react'
import ClickAwayListener from 'react-click-away-listener'
import ChatBot from 'react-simple-chatbot'

const Triggers = {
  greetings: 'greetings',
  helpMessage: 'help-message',
  helpOptions: 'help-options',
  doubt01: 'doubt-01',
  doubt02: 'doubt-02',
  doubt03: 'doubt-03',
  againMessage: 'again-message',
  againOptions: 'again-options',
  endBot: 'end-bot'
}

export const Chat = ({ dietBoxLink, personalWppLink, instagramLink }) => {
  const [isChatOpen, setIsChatOpen] = useState(false)
  const toggleIsChatOpen = () => setIsChatOpen(prev => !prev)

  const steps = useMemo(() => {
    return [
        {
          id: Triggers.greetings,
          message: 'Olá!',
          trigger: Triggers.helpMessage
        },
        {
          id: Triggers.helpMessage,
          message: 'Selecione como posso ajudar:',
          trigger: Triggers.helpOptions
        },
        {
          id: Triggers.helpOptions,
          options: [
            {
              value: 1, label: 'O que é a nutrição funcional?', trigger: Triggers.doubt01,
            },
            {
              value: 2, label: 'Como o guia low carb pode me ajudar?', trigger: Triggers.doubt02
            },
            {
              value: 3, label: 'Quais os tipos de serviços que a Nutri oferece?', trigger: Triggers.doubt03,
            }
          ],
        },
        {
          id: Triggers.doubt01,
          component: (
            <div className="custom-message">
              <p aria-label="Descrição sobre o que é nutrição funcional" role="contentinfo">
                A nutrição funcional foca na <strong>abordagem individualizada</strong> e na <strong>busca por identificar e corrigir desequilíbrios nutricionais específicos</strong>, ao invés de apenas focar em uma alimentação balanceada em geral. Minha conduta é <strong>oferecer técnicas e ferramentas estratégicas</strong> para ajudar <strong>você</strong> a alcançar um estilo de vida mais <strong>equilibrado</strong> e mais <strong>completo</strong>. Depois de sessões personalizadas, você vai estar pronto para tomar as melhores decisões relativas à sua alimentação para sua saúde. Não espere, <a href={dietBoxLink} target="_blank" rel="noreferrer" aria-label="Abrir link para realizar um agendamento com Sara França">marque já a sua consulta</a> ou <a href={personalWppLink} target="_blank" rel="noreferrer" aria-label="Entrar em contato com Sara França">entre em contato!</a>
              </p>
            </div>
          ),
          trigger: Triggers.againMessage
        },
        {
          id: Triggers.doubt02,
          component: (
            <div className="custom-message">
              <p aria-label="Descrição o guia low carb" role="contentinfo">
                Nele você vai ter <strong>todos</strong> os alimentos permitidos, saber os <strong>benefícios dessa alimentação</strong> com um <strong>cardápio montado</strong> e as <strong>receitas</strong> que vão te ajudar a <strong>variar</strong> sua alimentação. Esse guia vai fazer você perder de <strong>5 a 7 quilos em 15 dias</strong>, resgatar a sua <strong>auto estima</strong>, a sua disposição, entrar em forma e fazer as pazes com a balança! <a href={personalWppLink} target="_blank" rel="noreferrer" aria-label="Entrar em contato com Sara França">Dúvidas, entre em contato!</a>
              </p>
            </div>
          ),
          trigger: Triggers.againMessage
        },
        {
          id: Triggers.doubt03,
          component: (
            <div className="custom-message" role="contentinfo">
              <ol aria-label="Lista de serviços oferecidos pela nutricionista">
                <li>Avaliação on-line para orientação nutricional e definir objetivos;</li>
                <li>Consulta nutricional on-line;</li>
                <li>Consulta  nutricional presencial;</li>
                <li>Planejamento de cardápio familiar;</li>
                <li>Protocolos por objetivo (planos de 4 a 5 consultas);</li>
                <li>Inscrição em grupos de acompanhamento nutricional (datas pré definidas);</li>
                <li>Aquisição de e-books Nutrir.</li>
              </ol>
              <p aria-label="Adicionais sobre Sara França: seguir no instagram ou entrar em contato" role="contentinfo">
                Aproveite e já <a href={instagramLink} target="_blank" rel="noreferrer" aria-label="Seguir Sara França no instagram">me segue lá no Instagram</a> para saber mais sobre saúde e nutrição! Ou se preferir, <a href={personalWppLink} target="_blank" rel="noreferrer" aria-label="Entrar em contato com Sara França">entre em contato.</a>
              </p>
            </div>
          ),
          trigger: Triggers.againMessage
        },
        {
          id: Triggers.againMessage,
          message: 'Ajudo em algo mais?',
          trigger: Triggers.againOptions
        },
        {
          id: Triggers.againOptions,
          options: [
            {
              value: 1, label: 'Sim', trigger: Triggers.helpMessage
            },
            {
              value: 2, label: 'Não', trigger: Triggers.endBot
            }
          ]
        },
        {
          id: Triggers.endBot,
          message: 'Espero ter ajudado, até mais!',
          end: true
        }
      ]
  }, [dietBoxLink, personalWppLink, instagramLink])

  return (
    <ClickAwayListener onClickAway={() => setIsChatOpen(false)}>
      <div className="chat-container">
        <button 
          onClick={toggleIsChatOpen}
          className="shadow bg-info"
          style={{
            display: isChatOpen ? 'none' : 'flex'
          }}
          aria-label="Abrir chat"
        >
          <FontAwesomeIcon className="text-white" icon={['fas', isChatOpen ? 'times' : 'message']} role="img" />
        </button>
        {
          isChatOpen && (
            <ChatBot 
              steps={steps}
            />
          )
        }
      </div>
    </ClickAwayListener>
  )
}