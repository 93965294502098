import React from 'react'
import ReactDOM from 'react-dom/client'
import './sass/custom.scss'
import './plugins/fontawesome'
import { App } from './App'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
)
