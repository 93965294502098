import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fade } from 'react-awesome-reveal'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Parallax, Pagination } from 'swiper'
import { DELAY_MS, DIET_BOX_LINK, INSTAGRAM_POST_1, INSTAGRAM_POST_2, INSTAGRAM_POST_3, INSTAGRAM_POST_4 } from "../../../../utils/mockData/homeContent";
import 'swiper/css'
import 'swiper/css/parallax'
import 'swiper/css/autoplay'
import { NAVBAR_PATHS } from "../../../../utils/constants/paths";

export const Slider = () => {
  return (
    <div className="w-100">
      <Fade delay={DELAY_MS} triggerOnce={true} className="w-100">
        <h2 id={NAVBAR_PATHS.slider} className="fw-light display-5 mx-5 text-center sara-title text-primary">Publicações</h2>
        <section className="slider" role="complementary">
          <Swiper
            modules={[Autoplay, Parallax, Pagination]}
            autoplay={{ disableOnInteraction: false }}
            pagination={{ clickable: true }}
            slidesPerView={1}
          >
            <SwiperSlide>
              <div className="slide">
                <a
                  href={INSTAGRAM_POST_1}
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Link para acessar a publicação de Sara França sobre sinais que os bebês dão quando estão prontos para introdução alimentar"
                >
                  <img
                    src="/sinais_prontidao_bebe.jpg"
                    alt="Bebê sorrindo e comendo uma banana sentado na cadeirinha."
                    className="shadow"
                  />
                  <div className="btn btn-primary d-flex align-items-center gap-2 description shadow">
                    <FontAwesomeIcon icon={['fab', 'instagram']} />
                    <p className="m-0"> Acesse esse conteúdo </p>
                  </div>
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slide">
                <a
                  href={INSTAGRAM_POST_2}
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Link para acessar a publicação de Sara França sobre sugestões de lanches para você levar para o trabalho"
                >
                  <img
                    src="/sugestoes_lanches_trabalho.jpg"
                    alt="Sugestões de lanches para você levar para o trabalho."
                    className="shadow"
                    width="550px"
                    height="550px"
                  />
                  <div className="btn btn-primary d-flex align-items-center gap-2 description shadow">
                    <FontAwesomeIcon icon={['fab', 'instagram']} />
                    <p className="m-0"> Acesse esse conteúdo </p>
                  </div>
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slide">
                <a
                  href={INSTAGRAM_POST_3}
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Link para acessar a publicação de Sara França cardápio para você emagrecer e desinchar"
                >
                  <img
                    src="/cardapio_emagrecer.jpg"
                    alt="Cardápio para você emagrecer e desinchar."
                    className="shadow"
                    width="550px"
                    height="550px"
                  />
                  <div className="btn btn-primary d-flex align-items-center gap-2 description shadow">
                    <FontAwesomeIcon icon={['fab', 'instagram']} />
                    <p className="m-0"> Acesse esse conteúdo </p>
                  </div>
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slide">
                <a
                  href={INSTAGRAM_POST_4}
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Link para acessar a publicação de Sara França sobre o mega guia low carb"
                >
                  <img
                    src="/emagreca_ate_7kg.jpeg"
                    alt="À direita uma mulher com uma fita na barriga demonstrando seu peso perdido. Na esquerda, imagem do ebook em questão: 'Guia para perder de 5 a 7kg em 15 dias' e também o preço de R$ 47,90."
                    className="shadow"
                    width="550px"
                    height="550px"
                  />
                  <div className="btn btn-primary d-flex align-items-center gap-2 description shadow">
                    <FontAwesomeIcon icon={['fab', 'instagram']} />
                    <p className="m-0"> Acesse esse conteúdo </p>
                  </div>
                </a>
              </div>
            </SwiperSlide>
          </Swiper>
        </section>
      </Fade>
      {/* Agende uma consulta */}
      <div style={{ transform: 'translateY(5px)' }} id={NAVBAR_PATHS.book}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#BDDB99" fillOpacity="1" d="M0,224L48,234.7C96,245,192,267,288,266.7C384,267,480,245,576,224C672,203,768,181,864,186.7C960,192,1056,224,1152,234.7C1248,245,1344,235,1392,229.3L1440,224L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
      </div>
      <section className="d-flex justify-content-center align-items-center p-3" style={{ overflow: 'visible', backgroundColor: '#BDDB99', height: '115px' }} role="article">
        <a
          className="btn btn-primary d-flex align-items-center gap-3 btn-lg"
          href={DIET_BOX_LINK}
          target="_blank"
          rel="noreferrer"
          aria-label="Abrir link para realizar um agendamento com Sara França"
          id="dietbox-link"
        >
          <FontAwesomeIcon icon={['fas', 'calendar-alt']} role="img" />
          Agende uma consulta
        </a>
        <span style={{ position: 'absolute', transform: 'translateY(40px)', zIndex: 1 }}>
          <strong>Online</strong> ou <strong>presencial</strong>
        </span>
      </section>
    </div>
  )
}