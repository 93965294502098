export const CONTENT = {
  navbar: {
    services: 'Meu atendimentos',
    schedule: 'Sua nutricionista',
    slider: 'Publicações',
    stay_connected: 'Mantenha-se conectado',
    book: 'Agende sua consulta'
  }
}

export const DELAY_MS                                  = 10
export const HOT_MART_ID                               = 'hot-mart'
export const HOT_MART_LINK                             = 'https://hotm.art/u0FaTL'
export const HOT_MART_LINK_2                           = 'https://hotmart.com/pt-br/marketplace/produtos/suchas-segredos-da-nutri/H81419420L?sck=HOTMART_PRODUCT_PAGE'
export const HOT_MART_LINK_3                           = 'https://hotmart.com/pt-br/marketplace/produtos/cardapio-divertido-e-saudavel-para-criancas/E90351937S?sck=HOTMART_PRODUCT_PAGE'
export const HOT_MART_LINK_4                           = 'https://hotmart.com/pt-br/marketplace/produtos/receitas-saudaveis-para-cada-refeicao-do-seu-dia-mais-de-90-opcoes-para-voce/U81444495Q?sck=HOTMART_PRODUCT_PAGE'
export const HOT_MART_LINK_5                           = 'https://hotmart.com/pt-br/marketplace/produtos/perca-peso-com-a-dieta-low-carb-guia-para-voce-perder-de-5-a-7-kg-em-15-dias/N81025341U?sck=HOTMART_PRODUCT_PAGE'
export const HOT_MART_LINK_6                           = 'https://chk.eduzz.com/1871474'
export const HOT_MART_LINK_7                           = 'https://hotmart.com/pt-br/marketplace/produtos/seu-cardapio-saudavel-bonus-receitas-para-cafe-da-manha/S81420178B?sck=HOTMART_PRODUCT_PAGE'
export const HOT_MART_LINK_8                           = 'https://hotmart.com/pt-br/marketplace/produtos/dicas-poderosas-para-voce-entrar-em-forma/Y81445008V?sck=HOTMART_PRODUCT_PAGE'
export const INSTAGRAM_POST_1                          = 'https://www.instagram.com/p/CumFwzHJa4m/?utm_source=ig_web_copy_link&igsh=MjM0N2Q2NDBjYg%3D%3D'
export const INSTAGRAM_POST_2                          = 'https://www.instagram.com/p/CtFtwy5umrx/?utm_source=ig_web_copy_link&igsh=MjM0N2Q2NDBjYg%3D%3D&img_index=1'
export const INSTAGRAM_POST_3                          = 'https://www.instagram.com/p/CsTV0hcOWse/?img_index=1'
export const INSTAGRAM_POST_4                          = 'https://www.instagram.com/reel/CrQnKsQO51c/'
export const WPP_GROUP                                 = 'https://chat.whatsapp.com/CUucjCw1DnxB90Btj5FALM'
export const WPP_PERSONAL                              = 'https://wa.me/message/AHS34IEBVPMZB1'
export const DIET_BOX_LINK                             = 'https://wa.me/message/AHS34IEBVPMZB1'
export const INSTAGRAM_LINK                            = 'https://instagram.com/sarafrancanutrir?igshid=NTdlMDg3MTY='
export const WPP_CATALOG                               = 'https://www.whatsapp.com/catalog/5521967865700/?app_absent=0'