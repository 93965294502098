import { Swiper, SwiperSlide } from 'swiper/react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Autoplay, Parallax, Pagination } from 'swiper'
import { NAVBAR_PATHS } from "../../../../utils/constants/paths"
import { DIET_BOX_LINK, HOT_MART_ID } from "../../../../utils/mockData/homeContent"

import 'swiper/css/pagination';

export const Intro = () => {
  return (
    <div style={{ minHeight: '450px' }} className="d-flex w-100 flex-column align-items-center">
      <div className="w-100 flex-grow-1 d-flex align-items-center justify-content-center position-relative flex-column" style={{ backgroundColor: '#BDDB99' }}>
        <div className="d-flex w-100 flex-column flex-md-row flex-lg-row justify-content-center align-items-center p-sm-3 p-md-4 gap-md-5 gap-lg-5 mb-sm-3" style={{ maxWidth: '1750px' }}>
          <section className="d-flex align-items-center justify-content-center flex-column mx-2 pt-5">
            <h3 className="text-center text-primary display-5 fs-3 mb-3 sara-title"> <strong>Nutrição infantil e saúde da mulher</strong> </h3>
            <div className="d-flex justify-content-center align-items-center mx-3 flex-column">
              <video
                controls
                autoPlay={true}
                muted={true}
                style={{ minWidth: '300px', maxWidth: '100%', maxHeight: '350px', borderRadius: '5px' }}
                className="shadow"
              >
                <source src="intro.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <p className="mt-3 display-5 fs-5 text-primary sara-title text-center"> <strong>Transforme</strong> sua vida com a nutrição. </p>
            </div>
          </section>
          <section className="d-flex flex-column-reverse align-items-center flex-grow-1">
            {/* Biblioteca da nutri */}
            <div className="d-flex justify-content-center align-items-center" style={{ paddingBottom: '16px' }}>
              <a
                className="btn btn-outline-primary btn-lg d-flex align-items-center gap-3 justify-content-center"
                style={{ minWidth: '270px' }}
                href={`#${HOT_MART_ID}`}
                rel="noreferrer"
                aria-label="Abrir link para realizar um agendamento com Sara França"
                id="dietbox-link"
              >
                <FontAwesomeIcon icon={['fas', 'book']} />
                Biblioteca da nutri
              </a>
            </div>
            {/* Agende uma consulta */}
            <div className="d-flex justify-content-center align-items-center my-3" role="article">
              <a
                className="btn btn-primary btn-lg d-flex align-items-center gap-3 justify-content-center"
                style={{ minWidth: '270px' }}
                href={DIET_BOX_LINK}
                target="_blank"
                rel="noreferrer"
                aria-label="Abrir link para realizar um agendamento com Sara França"
                id="dietbox-link"
              >
                <FontAwesomeIcon icon={['fas', 'calendar-alt']} role="img" />
                Agende uma consulta
              </a>
            </div>
            <div className="d-flex justify-content-center align-items-center flex-column gap-2">
              <div className="d-flex justify-content-center align-items-center p-3">
                <img src="/logo-sara.png" alt="Logo Sara França Nutricionista" style={{ maxWidth: '275px' }} />
              </div>
              <h3 className="text-primary display-4 fs-5 mb-3"><strong>CRN 06100074</strong></h3>
            </div>
          </section>
          <section className="d-lg-flex flex-column align-items-center d-none">
            <div className="align-items-center justify-content-center sara-img">
              <img alt="Sara França" src="/sara_franca_rosa.png" className="w-100" />
            </div>
          </section>
        </div>
      </div>
      <div className="bg-body-tertiary d-flex align-items-center flex-column w-100 pt-5">
        <h2 id={NAVBAR_PATHS.services} className="fw-light display-5 mx-5 sara-title text-primary">Meus atendimentos</h2>
        <section className="slider-2 w-100 pt-3">
          <Swiper
            modules={[Autoplay, Parallax, Pagination]}
            autoplay={{ disableOnInteraction: false, pauseOnMouseEnter: true }}
            pagination={{ clickable: true }}
            slidesPerView={1}
          >
            <SwiperSlide>
              <div className="slide-2 d-flex justify-content-center align-items-center">
                <img
                  src="/nutricao_que_cresce_com_voce.png"
                  alt="Textos sobre nutrição de gestantes."
                  className="shadow"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slide-2 d-flex justify-content-center align-items-center">
                <img
                  src="/nutricao_criancas.png"
                  alt="Textos sobre nutrição de crianças."
                  className="shadow"
                />
              </div>
            </SwiperSlide>
          </Swiper>
        </section>
      </div>
    </div>
  )
}