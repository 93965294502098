import { Fade } from 'react-awesome-reveal'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { DELAY_MS, HOT_MART_ID, HOT_MART_LINK, HOT_MART_LINK_2, HOT_MART_LINK_3, HOT_MART_LINK_6, HOT_MART_LINK_7, HOT_MART_LINK_8, WPP_GROUP } from "../../../../utils/mockData/homeContent"
import { NAVBAR_PATHS } from "../../../../utils/constants/paths"

export const StayConnected = () => {
  return (
    <section className="w-100">
      <Fade delay={DELAY_MS} triggerOnce={true}>
        <h2 id={`${HOT_MART_ID}`} className="fw-light display-5 mx-5 sara-title text-primary text-center">Biblioteca da Nutri</h2>
        <section role="main" className="d-flex align-items-center justify-content-center flex-column w-100">
          <div className="w-100 d-flex justify-content-center align-items-center" style={{ maxWidth: '1500px' }}>
            <div className="d-flex align-items-center justify-content-md-center justify-content-sm-center p-3 flex-wrap gap-3" role="contentinfo">
              {/* Suchás Segredos da Nutri */}
              <div className="card rounded-3 shadow-sm" style={{ width: '350px', height: '450px' }}>
                <div className="card-header py-3">
                  <h4 className="my-0 fw-normal" style={{ maxWidth: '100%', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} title="Suchás Segredos da Nutri">Suchás Segredos da Nutri</h4>
                </div>
                <div className="card-body d-flex flex-column align-items-center">
                  <img
                    className="img-fluid shadow rounded-2 m-auto"
                    style={{ height: '300px' }}
                    src="/suchas.webp"
                    alt="Copo cheio de frutas cortadas."
                  />
                  <a
                    className="btn btn-primary w-100 mt-3 d-flex align-items-center gap-3 justify-content-center"
                    href={HOT_MART_LINK_2}
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Botão de conversão para o HotMart - Suchás Segredos da Nutri"
                  >
                    <FontAwesomeIcon icon={['fas', 'bookmark']} />
                    Acesse o e-book!
                  </a>
                </div>
              </div>
              {/* Receitas Low Carb */}
              <div className="card rounded-3 shadow-sm" style={{ width: '350px', height: '450px' }}>
                <div className="card-header py-3">
                  <h4 className="my-0 fw-normal" style={{ maxWidth: '100%', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} title="Receitas Low Carb">Receitas Low Carb</h4>
                </div>
                <div className="card-body d-flex flex-column align-items-center">
                  <img
                    className="img-fluid shadow rounded-2 m-auto"
                    style={{ height: '300px' }}
                    src="/dieta.png"
                    alt="Mulher comendo fruta."
                  />
                  <a
                    className="btn btn-primary w-100 mt-3 d-flex align-items-center gap-3 justify-content-center"
                    href={HOT_MART_LINK_6}
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Botão de conversão para o HotMart - Receitas low carb"
                  >
                    <FontAwesomeIcon icon={['fas', 'bookmark']} />
                    Acesse o e-book!
                  </a>
                </div>
              </div>
              {/* Seu cardápio saudável */}
              <div className="card rounded-3 shadow-sm" style={{ width: '350px', height: '450px' }}>
                <div className="card-header py-3">
                  <h4 className="my-0 fw-normal" style={{ maxWidth: '100%', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} title="Seu cardápio saudável">Seu cardápio saudável</h4>
                </div>
                <div className="card-body d-flex flex-column align-items-center">
                  <img
                    className="img-fluid shadow rounded-2 m-auto"
                    style={{ height: '300px' }}
                    src="/cardapio_saudavel.webp"
                    alt="Seu cardápio saudável."
                  />
                  <a
                    className="btn btn-primary w-100 mt-3 d-flex align-items-center gap-3 justify-content-center"
                    href={HOT_MART_LINK_7}
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Botão de conversão para o HotMart - Seu cardápio saudável"
                  >
                    <FontAwesomeIcon icon={['fas', 'bookmark']} />
                    Acesse o e-book!
                  </a>
                </div>
              </div>
              {/* Sabotadores do seu emagrecimento */}
              <div className="card rounded-3 shadow-sm" style={{ width: '350px', height: '450px' }}>
                <div className="card-header py-3">
                  <h4 className="my-0 fw-normal" style={{ maxWidth: '100%', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} title="Sabotadores do seu emagrecime">Sabotadores do seu emagrecimento</h4>
                </div>
                <div className="card-body d-flex flex-column align-items-center">
                  <img
                    className="img-fluid shadow rounded-2 m-auto"
                    style={{ height: '300px' }}
                    src="/sabotadores.webp"
                    alt="Mulher visualizando ela entrando numa calça com número menor, ou seja, visualizando seu emagrecimento."
                  />
                  <a
                    className="btn btn-primary w-100 mt-3 d-flex align-items-center gap-3 justify-content-center"
                    href={HOT_MART_LINK}
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Botão de conversão para o HotMart - Sabotadores do seu emagrecimento"
                  >
                    <FontAwesomeIcon icon={['fas', 'bookmark']} />
                    Acesse o e-book!
                  </a>
                </div>
              </div>
              {/* Cardápio para crianças */}
              <div className="card rounded-3 shadow-sm" style={{ width: '350px', height: '450px' }}>
                <div className="card-header py-3">
                  <h4 className="my-0 fw-normal" style={{ maxWidth: '100%', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} title="Cardápio para crianças">Cardápio para crianças</h4>
                </div>
                <div className="card-body d-flex flex-column align-items-center">
                  <img
                    className="img-fluid shadow rounded-2 m-auto"
                    style={{ height: '300px' }}
                    src="/cardapio_criancas.webp"
                    alt="Mão de criança pintada com cores azul, roxa, verde, amarela, vermelha e laranja e um rosto simples desenhado na palma da mão."
                  />
                  <a
                    className="btn btn-primary w-100 mt-3 d-flex align-items-center gap-3 justify-content-center"
                    href={HOT_MART_LINK_3}
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Botão de conversão para o HotMart - Cardápio para crianças"
                  >
                    <FontAwesomeIcon icon={['fas', 'bookmark']} />
                    Acesse o e-book!
                  </a>
                </div>
              </div>
              {/* Guia Perder Peso */}
              <div className="card rounded-3 shadow-sm" style={{ width: '350px', height: '450px' }}>
                <div className="card-header py-3">
                  <h4 className="my-0 fw-normal" style={{ maxWidth: '100%', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} title="Guia Low Carb">Guia Low Carb</h4>
                </div>
                <div className="card-body d-flex flex-column align-items-center">
                  <img
                    className="img-fluid shadow rounded-2 m-auto"
                    style={{ height: '300px' }}
                    src="/guia_perder_peso.webp"
                    alt="Guia para perder peso com a dieta low carb."
                  />
                  <a
                    className="btn btn-primary w-100 mt-3 d-flex align-items-center gap-3 justify-content-center"
                    href={HOT_MART_LINK}
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Botão de conversão para o HotMart - Guia para perder peso com a dieta low carb"
                  >
                    <FontAwesomeIcon icon={['fas', 'bookmark']} />
                    Acesse o e-book!
                  </a>
                </div>
              </div>
              {/* 40 dicas para entrar em forma */}
              <div className="card rounded-3 shadow-sm" style={{ width: '350px', height: '450px' }}>
                <div className="card-header py-3">
                  <h4 className="my-0 fw-normal" style={{ maxWidth: '100%', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} title="40 dicas para entrar em forma">40 dicas para entrar em forma</h4>
                </div>
                <div className="card-body d-flex flex-column align-items-center">
                  <img
                    className="img-fluid shadow rounded-2 m-auto"
                    style={{ height: '300px' }}
                    src="/dicas_entrar_em_forma.webp"
                    alt="40 dicas da nutricionsita para você entrar em forma."
                  />
                  <a
                    className="btn btn-primary w-100 mt-3 d-flex align-items-center gap-3 justify-content-center"
                    href={HOT_MART_LINK_8}
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Botão de conversão para o HotMart - Guia de emagrecimento rápido"
                  >
                    <FontAwesomeIcon icon={['fas', 'bookmark']} />
                    Acesse o e-book!
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Fade>
      {/* Mantenha-se conectado */}
      <div style={{ transform: 'translateY(5px)' }}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#B91251" fillOpacity="1" d="M0,224L48,234.7C96,245,192,267,288,266.7C384,267,480,245,576,224C672,203,768,181,864,186.7C960,192,1056,224,1152,234.7C1248,245,1344,235,1392,229.3L1440,224L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
      </div>
      <section className="d-flex bg-primary flex-column justify-content-center align-items-center p-3" role="article" id={NAVBAR_PATHS.stay_connected}>
        <div className="content-aux">
          <div className="d-flex justify-content-center align-items-center">
            <a
              href={WPP_GROUP}
              target="_blank"
              rel="noreferrer"
              className="btn btn-info d-flex gap-3 align-items-center btn-lg"
              aria-label="Abrir link de grupo do WhatsApp de Sara França"
              aria-labelledby="wpp-group"
            >
              <FontAwesomeIcon icon={['fab', 'whatsapp']} role="img" />
              Mantenha-se conectado
            </a>
          </div>
        </div>
      </section>
      <div style={{ transform: 'translateY(-5px) rotate(180deg)' }}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path fill="#B91251" fillOpacity="1" d="M0,0L48,32C96,64,192,128,288,170.7C384,213,480,235,576,245.3C672,256,768,256,864,240C960,224,1056,192,1152,186.7C1248,181,1344,203,1392,213.3L1440,224L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
        </svg>
      </div>
    </section>
  )
}