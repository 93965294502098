import { DIET_BOX_LINK, INSTAGRAM_LINK, WPP_PERSONAL } from '../../utils/mockData/homeContent'
import { GenericNavbar } from '../../components/GenericNavbar'
import { Chat } from '../../components/Chat'
import { GenericFooter } from '../../components/GenericFooter'
import { Intro } from './components/Intro'
import { Schedule } from './components/Schedule'
import { Slider } from './components/Slider'
import { StayConnected } from './components/StayConnected'
import { Location } from './components/Location'

export const Home = () => {
  return (
    <>
      <GenericNavbar />
      <Intro />
      <Schedule />
      <StayConnected />
      <Slider />
      <Location />
      <Chat dietBoxLink={DIET_BOX_LINK} personalWppLink={WPP_PERSONAL} instagramLink={INSTAGRAM_LINK} />
      <GenericFooter />
    </>
  )
}